import md5 from 'blueimp-md5'
import { useCallback } from 'react'
import useAppState from './useAppState'

const useLocalizedAirline = () => {
    const { airlineCodes } = useAppState()
    const getLocalizedAirline = useCallback((airlineCode) => {
        if (!airlineCode) {
            return {
                name: null,
                imageSquareUrl: null,
                imageUrl: null,
            }
        }
        const name = airlineCodes[airlineCode] || null
        const imageThumbRectangleHash = md5(`${airlineCode}_80_48_r_36XzF77ZDLemHWQ4fZaPaBw9Srvgw7dy`)
        const imageSquareHash = md5(`${airlineCode}_100_100_s_36XzF77ZDLemHWQ4fZaPaBw9Srvgw7dy`)
        const imageHash = md5(`${airlineCode}_700_200_r_36XzF77ZDLemHWQ4fZaPaBw9Srvgw7dy`)
        return {
            code: airlineCode,
            name,
            imageThumbRectangle: `https://content.airhex.com/content/logos/airlines_${airlineCode}_80_48_r.png?md5apikey=${imageThumbRectangleHash}&proportions=keep`,
            imageSquareUrl: `https://content.airhex.com/content/logos/airlines_${airlineCode}_100_100_s.png?md5apikey=${imageSquareHash}`,
            imageUrl: `https://content.airhex.com/content/logos/airlines_${airlineCode}_700_200_r.png?md5apikey=${imageHash}&proportions=keep`,
        }
    }, [airlineCodes])

    return getLocalizedAirline
}

export default useLocalizedAirline
