import React from 'react'
import useFlightProps from '../hooks/useFlightProps'
import useFlightSearchExperiment from '../hooks/useFlightSearchExperiment'
import SegmentDetailsMobile from './SegmentDetailsMobile'
import SegmentDetailsDesktop from './SegmentDetailsDesktop'
import SegmentDetailsDesktopDeprecated from './SegmentDetailsDesktopDeprecated'

const SegmentDetails = ({
    segment,
    baggageAllowed,
}) => {
    const { updatedUi } = useFlightSearchExperiment()
    const { getFlightPropsFromSegment } = useFlightProps()
    const props = getFlightPropsFromSegment(segment)

    return (
        <>
            {updatedUi
                ? <SegmentDetailsDesktop {...props} baggageAllowed={baggageAllowed} />
                : <SegmentDetailsDesktopDeprecated {...props} baggageAllowed={baggageAllowed} />}
            <SegmentDetailsMobile {...props} baggageAllowed={baggageAllowed} />
        </>
    )
}

export default SegmentDetails
