import { useRouter } from 'next-nprogress-bar'
import * as constants from '@connections/constants'
import { getLocaleRoute } from '../../util/routes'
import usePathnameLocale from './usePathnameLocale'

const {
    LANGUAGE_NL_BE: NL_BE,
} = constants

const useLocaleNavigateTo = () => {
    const router = useRouter()
    const pathnameLocale = usePathnameLocale(true)

    return (href, options = {}) => {
        let route
        let locale = options.locale || pathnameLocale
        if (typeof href === 'string') {
            route = getLocaleRoute({ locale, pathname: href })
        } else {
            route = getLocaleRoute({ locale, ...href })
        }
        if (route === '/' && locale === NL_BE) {
            locale = ''
        }
        return router.push(route, route, {
            ...options,
            locale
        })
    }
}

export default useLocaleNavigateTo
