import React from 'react'
import { Trans } from '@lingui/react'
import { millisecondsToFlightDurationString } from '@connections/utils'
import Icon from './Icon'

const LayoverTime = ({
    milliseconds,
}) => {
    // 2 hours
    if (milliseconds <= 7200000) {
        return <Trans id="Short layover" />
    }
    // 4 hours
    if (milliseconds <= 14400000) {
        return <Trans id="Medium layover" />
    }
    return <Trans id="Long layover" />
}

const SegmentLayoverDivider = ({
    arrivalTime,
    departureTime,
}) => {
    const layoverTimeMs = new Date(departureTime).getTime() - new Date(arrivalTime).getTime()

    return (
        <div className="flex items-center my-5 lg:justify-between">
            <div className="flex flex-1 items-center">
                <div className="flex-1 border-b border-gray-100 border-dashed lg:flex-none lg:w-8" />
                <div className="flex mx-6">
                    <Icon
                        name="clock"
                        color="blue"
                        size="smaller"
                    />
                    <p className="text-xs">
                        <span className="mx-2 lg:font-bold">
                            {millisecondsToFlightDurationString(layoverTimeMs)}
                        </span>
                        <Trans id="Layover" />
                    </p>
                </div>
                <div className="flex-1 border-b border-gray-100 border-dashed lg:hidden" />
            </div>
            <div className="hidden lg:block">
                <p className="text-xs text-blue">
                    <LayoverTime milliseconds={layoverTimeMs} />
                </p>
            </div>
        </div>
    )
}

export default SegmentLayoverDivider
