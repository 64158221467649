import React from 'react'
import { usePathname } from 'next/navigation'
import { isClient } from '../../util/index'
import usePageProps from '../hooks/usePageProps'

export default function PagePreviewNotification() {
    const pathname = usePathname()
    const { isPreview } = usePageProps()

    const isIframe = isClient && window.location !== window.parent.location
    const exitPreviewPath = `/api/exit-preview?slug=${pathname}`

    if (!isPreview || isIframe) {
        return null
    }

    return (
        <div className="fixed bg-blue-bright text-white rounded-lg mx-4 bottom-4 md:bottom-8 md:left-8 px-8 py-4 z-modal">
            Your are visiting the page in preview mode
            <a href={exitPreviewPath} className="underline font-bold ml-2">
                exit preview mode
            </a>
        </div>
    )
}
