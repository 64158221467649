import { addDays } from 'date-fns'

export const applyRule = (result, exclude) => {
    if (exclude) {
        return !result
    }
    return result
}

export const getMsFromTimeString = (hoursString, minutesString) => {
    const hours = parseInt(hoursString, 10)
    const minutes = parseInt(minutesString, 10)
    return (hours * 60 * 60 * 1000) + (minutes * 60 * 1000)
}

const isWeekendDay = (date) => {
    const day = date.getDay()
    // 6 = saturday, 0 = sunday
    return day === 6 || day === 0
}

export const amountOfWorkDaysBeforeToCalendarDaysBefore = (workDays, referenceDate) => {
    let calendarDays = 0
    let daysRemaining = workDays
    while (daysRemaining > 0) {
        const date = addDays(referenceDate, -(calendarDays + 1))
        if (!isWeekendDay(date)) {
            daysRemaining -= 1
        }
        calendarDays += 1
    }
    return calendarDays
}

export const booleanMatchesYesNoList = (list, boolean) => {
    if (list.length === 0) {
        return true
    }
    return (boolean && list.includes('yes')) || (!boolean && list.includes('no'))
}

export const valueMatchesList = (list, value) => {
    if (list.length === 0) {
        return true
    }
    return list.includes(value)
}

export const ruleStatusesToResult = (statuses) => statuses.filter((status) => status !== null).every((status) => status === true)
