import React from 'react'
import { Trans } from '@lingui/react'
import Icon from './Icon'
import Date from './Date'

export const getRouteLocationIcon = (placeType, routeType) => {
    switch (placeType) {
        case 'Airport':
            return routeType === 'departure' ? 'plane-arrival' : 'plane-departure'
        case 'Hotel':
            return 'building'
        default:
            return 'map-marker'
    }
}

const TransportOverview = ({
    className,
    transportItem,
}) => {
    const { data: { transport: { routingList } } } = transportItem
    const [route] = routingList
    const {
        arrival,
        departure,
        startDate,
    } = route
    return (
        <div className={className}>
            <Date
                dateString={startDate}
                format="eee d MMM yyyy"
                className="text-sm font-medium uppercase"
            />
            <div className="mt-4">
                <div className="flex items-center">
                    <Icon
                        size="small"
                        color="blue-bright"
                        name={getRouteLocationIcon(departure.placeType, 'departure')}
                    />
                    <p className="text-sm text-gray-800 ml-2">
                        {departure.placeType === 'Hotel'
                            ? (
                                <Trans
                                    id="Hotel in {city}"
                                    values={{ city: departure.city.text }}
                                />
                            )
                            : departure.city.text}
                    </p>
                </div>
                <Icon
                    size="small"
                    className="my-2"
                    name="arrow-down"
                />
                <div className="flex items-center">
                    <Icon
                        size="small"
                        color="blue-bright"
                        name={getRouteLocationIcon(arrival.placeType, 'arrival')}
                    />
                    <p className="text-sm text-gray-800 ml-2">
                        {arrival.placeType === 'Hotel'
                            ? (
                                <Trans
                                    id="Hotel in {city}"
                                    values={{ city: arrival.city.text }}
                                />
                            )
                            : arrival.city.text}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default TransportOverview
