import React from 'react'
import { Trans } from '@lingui/react'
import clsx from 'clsx'

const BookingGuestAmounts = ({
    adults,
    className,
    numberOfRooms,
    numberOfChildren,
}) => (
    <div
        className={clsx(
            'flex text-xs font-medium text-gray-400',
            className,
        )}
    >
        <p className="uppercase">
            {numberOfRooms}
            {' '}
            {numberOfRooms === 1
                ? <Trans id="room" />
                : <Trans id="rooms" />}
            {' - '}
            {adults}
            {' '}
            {adults === 1
                ? <Trans id="adult" />
                : <Trans id="adults" />}
            {numberOfChildren > 0 && (
                <>
                    {' - '}
                    {numberOfChildren}
                    {' '}
                    {numberOfChildren === 1
                        ? <Trans id="child" />
                        : <Trans id="children" />}
                </>
            )}
        </p>
    </div>
)

export default BookingGuestAmounts
