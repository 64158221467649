import * as constants from '@connections/constants'
import { hasPossibleBrandedFares, fareTimeStringToMilliseconds } from '@connections/utils'

const {
    OPTION_ADDON: ADDON,
    FARE_TYPE_FIRST: FIRST,
    FARE_TYPE_COACH: COACH,
    OPTION_INCLUDED: INCLUDED,
    OPTION_EXCLUDED: EXCLUDED,
    FARE_TYPE_ECONOMY: ECONOMY,
    FARE_TYPE_BUSINESS: BUSINESS,
    FARE_TYPE_ECONOMY_PLUS: ECONOMY_PLUS,
} = constants

export const getCabinClass = (fareType) => {
    switch (fareType) {
        case ECONOMY:
            return 'ECONOMY'
        case ECONOMY_PLUS:
            return 'ECONOMY_PLUS'
        case COACH:
            return 'COACH'
        case BUSINESS:
            return 'BUSINESS'
        case FIRST:
            return 'FIRST'
        default:
            return null
    }
}

export const getCabinClassString = (cabinClassNumber) => {
    switch (cabinClassNumber) {
        case 1:
            return 'Economy'
        case 2:
            return 'Business'
        case 4:
            return 'First'
        case 8:
            return 'Premium'
        default:
            return ''
    }
}

export const toSegments = (params) => params.from.reduce((segments, param, index) => {
    const to = params.to[index]
    const from = params.from[index]
    const returnDate = params.return
    const departureDate = params.departure[index]
    if (typeof from === 'undefined' || typeof to === 'undefined' || typeof departureDate === 'undefined') {
        return segments
    }
    return [
        ...segments,
        {
            to,
            from,
            departureDate,
            returnDate: returnDate !== '' ? returnDate : null,
        }]
}, [])

export function calculateAverageFareTravelTime(airRoutes) {
    const totalTravelTime = airRoutes.reduce((total, { travelTime }) => total + fareTimeStringToMilliseconds(travelTime), 0)
    return totalTravelTime / airRoutes.length
}

export function getFareLuggageStatus(fare, mode, infants, cabinClass) {
    const { isLowCost, baggageAllowed } = fare
    if (baggageAllowed) {
        return INCLUDED
    }
    const hasAddon = isLowCost || hasPossibleBrandedFares(fare, mode, infants, cabinClass)
    if (hasAddon) {
        return ADDON
    }
    return EXCLUDED
}
