import React from 'react'
import { Trans } from '@lingui/react'
import { millisecondsToFlightDurationString } from '@connections/utils'
import { getCabinClassString } from '../../util/booking/flights'
import SegmentDetailBannerRowDeprecated, { INBOUND, OUTBOUND } from './SegmentDetailBannerRowDeprecated'

const SegmentDetailsDesktopDeprecated = ({
    duration,
    cabinClass,
    originCity,
    arrivalTime,
    departureTime,
    baggageAllowed,
    destinationCity,
    localizedAirline,
    localizedOperatingAirline,
    operatingFlightNumber,
}) => (
    <div className="hidden lg:grid grid-cols-12 gap-x-2 gap-y-3 text-xs px-2 py-3">
        <SegmentDetailBannerRowDeprecated
            type={OUTBOUND}
            city={originCity}
            dateTime={departureTime}
        />
        <div className="col-span-full h-px bg-gray-100 -mx-2" />
        <div className="relative w-5 h-5 bg-gray-100">
            {localizedAirline?.imageSquareUrl && (
                <img
                    alt={localizedAirline.name}
                    src={localizedAirline.imageSquareUrl}
                    className="absolute inset-0 object-cover h-full w-full"
                />
            )}
        </div>
        <div className="col-span-11 grid grid-cols-7 gap-2">
            <div className="col-span-3 grid grid-cols-3 gap-2">
                {duration !== 0 && (
                    <>
                        <p className="font-medium">
                            <Trans id="Travel time" />:
                        </p>
                        <p className="col-span-2">
                            {millisecondsToFlightDurationString(duration)}
                        </p>
                    </>
                )}
                <p className="font-medium">
                    <Trans id="Cabin" />:
                </p>
                <p className="col-span-2">
                    {parseInt(cabinClass, 10)
                        ? getCabinClassString(parseInt(cabinClass, 10))
                        : cabinClass}
                </p>
                <p className="font-medium">
                    <Trans id="Baggage" />:
                </p>
                <p className="col-span-2">
                    {baggageAllowed
                        ? <Trans id="Luggage included" />
                        : <Trans id="Luggage excluded" />}
                </p>
            </div>
            <div className="h-fit col-span-4 grid grid-cols-2 gap-2">
                {operatingFlightNumber && (
                    <>
                        <p className="font-medium capitalize text-gray-400">
                            <Trans id="Flight" />:
                        </p>
                        <p>
                            {operatingFlightNumber}
                        </p>
                    </>
                )}
                <p className="font-medium text-gray-400">
                    <Trans id="Airline" />:
                </p>
                <p>
                    {localizedAirline.name}
                </p>
                {localizedOperatingAirline.name && localizedOperatingAirline.name !== localizedAirline.name ? (
                    <>
                        <p className="font-medium text-gray-400">
                            <Trans id="Operated by" />:
                        </p>
                        <p>
                            {localizedOperatingAirline.name}
                        </p>
                    </>
                ) : null}
            </div>
        </div>
        <div className="col-span-full h-px bg-gray-100 -mx-2" />
        <SegmentDetailBannerRowDeprecated
            type={INBOUND}
            dateTime={arrivalTime}
            city={destinationCity}
        />
    </div>
)

export default SegmentDetailsDesktopDeprecated
