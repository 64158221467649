import React from 'react'
import { Trans } from '@lingui/react'
import * as constants from '@connections/constants'
import useFormatDate from '../hooks/useFormatDate'

const {
    FLIGHT_TYPE_TO: TO,
    FLIGHT_TYPE_FROM: FROM,
} = constants

const getFlightString = (type) => {
    switch (type) {
        case TO:
            return <Trans id="Departure" />
        case FROM:
            return <Trans id="Return" />
        default:
            return <Trans id="Flight" />
    }
}

const FlightOverviewHeading = ({
    isLoading,
    airRouteType,
    departureTime,
}) => {
    const format = useFormatDate()
    return (
        <div className="flex mb-3.5 text-sm text-gray-400 font-medium">
            {getFlightString(airRouteType)}:
            <div className="ml-1">
                {isLoading ? (
                    <div className="skeleton rounded-sm w-28 h-4" />
                ) : (
                    format(new Date(departureTime), 'dd MMMM yyyy')
                )}
            </div>
        </div>
    )
}

export default FlightOverviewHeading
