import { fareTimeStringToMilliseconds } from '@connections/utils'
import useLocalizedCity from './useLocalizedCity'
import useLocalizedAirline from './useLocalizedAirline'

const useFlightProps = () => {
    const getLocalizedCity = useLocalizedCity()
    const getLocalizedAirline = useLocalizedAirline()

    const getFlightPropsFromAirRoute = (airRoute) => {
        if (!airRoute) {
            return {}
        }
        const {
            id,
            segments,
            travelTime,
            airRouteType,
        } = airRoute
        const firstSegment = segments[0]
        const lastSegment = segments[segments.length - 1]
        const { origin: { code: origin }, departureTime } = firstSegment
        const { destination: { code: destination }, arrivalTime, carrier } = lastSegment
        const departureDay = new Date(departureTime).getUTCDate()
        const arrivalDay = new Date(arrivalTime).getUTCDate()
        const landsDifferentDay = departureDay !== arrivalDay
        return {
            id,
            origin,
            segments,
            destination,
            arrivalTime,
            airRouteType,
            departureTime,
            landsDifferentDay,
            stops: segments.length - 1,
            originCity: getLocalizedCity(origin),
            carrier: getLocalizedAirline(carrier),
            destinationCity: getLocalizedCity(destination),
            duration: travelTime ? fareTimeStringToMilliseconds(travelTime) : 0,
        }
    }

    const getFlightPropsFromSegment = ({
        carrier,
        equipment,
        flightTime,
        cabinClass,
        arrivalTime,
        flightNumber,
        departureTime,
        segmentNumber,
        availableSeats,
        operatingCarrier,
        operatingFlightNumber,
        origin: { code: origin },
        destination: { code: destination },
    },) => {
        const originCity = getLocalizedCity(origin)
        const destinationCity = getLocalizedCity(destination)
        const localizedAirline = getLocalizedAirline(carrier)
        const localizedOperatingAirline = getLocalizedAirline(operatingCarrier)
        const duration = flightTime ? fareTimeStringToMilliseconds(flightTime) : 0
        return {
            origin,
            duration,
            equipment,
            cabinClass,
            originCity,
            flightTime,
            arrivalTime,
            destination,
            flightNumber,
            departureTime,
            segmentNumber,
            availableSeats,
            destinationCity,
            localizedAirline,
            operatingFlightNumber,
            localizedOperatingAirline,
        }
    }

    return {
        getFlightPropsFromAirRoute,
        getFlightPropsFromSegment,
    }
}

export default useFlightProps
