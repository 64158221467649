'use client'

import React from 'react'
import PagePreviewNotification from '../layout/PagePreviewNotification'
import PagePropsProvider from './PagePropsProvider'

const PageProviders = ({
    children,
    pageProps
}) => (
    <PagePropsProvider value={pageProps}>
        {children}
        <PagePreviewNotification />
    </PagePropsProvider>
)

export default PageProviders
