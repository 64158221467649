import React from 'react'
import clsx from 'clsx'
import { Trans } from '@lingui/react'
import { millisecondsToFlightDurationString } from '@connections/utils'
import { getCabinClassString } from '../../util/booking/flights'
import useFlightSearchExperiment from '../hooks/useFlightSearchExperiment'
import SegmentDetailBannerRowDeprecated from './SegmentDetailBannerRowDeprecated'
import SegmentDetailBannerRow, { INBOUND, OUTBOUND } from './SegmentDetailBannerRow'

const Label = ({
    children,
}) => (
    <p className="col-span-2 text-gray-400 break-words mr-2">
        {children}
    </p>
)

const SegmentDetailsMobile = ({
    duration,
    equipment,
    cabinClass,
    originCity,
    arrivalTime,
    departureTime,
    baggageAllowed,
    destinationCity,
    localizedAirline,
    operatingFlightNumber,
    localizedOperatingAirline,
}) => {
    const { updatedUi } = useFlightSearchExperiment()

    return (
        <div className="grid grid-cols-10 gap-x-2 gap-y-3 text-xs px-2 py-3 lg:hidden">
            {updatedUi ? (
                <SegmentDetailBannerRow
                    type={OUTBOUND}
                    city={originCity}
                    equipment={equipment}
                    dateTime={departureTime}
                />
            ) : (
                <SegmentDetailBannerRowDeprecated
                    type={OUTBOUND}
                    city={originCity}
                    dateTime={departureTime}
                />
            )}
            <div className="col-span-full h-px bg-gray-100 -mx-2" />
            <div className="relative w-5 h-5 bg-gray-100">
                {localizedAirline?.imageSquareUrl && (
                    <img
                        alt={localizedAirline.name}
                        src={localizedAirline.imageSquareUrl}
                        className="absolute inset-0 object-cover h-full w-full"
                    />
                )}
            </div>
            <div />
            <div className={clsx('col-span-7 text-xs', updatedUi && 'font-medium')}>
                {duration !== 0 && (
                    <div className="grid grid-cols-4 mb-1">
                        <Label>
                            <Trans id="Travel time" />:
                        </Label>
                        <p className="col-span-2">
                            {millisecondsToFlightDurationString(duration)}
                        </p>
                    </div>
                )}
                <div className="grid grid-cols-4 mb-1">
                    <Label>
                        <Trans id="Cabin" />:
                    </Label>
                    <p className="col-span-2">
                        {parseInt(cabinClass, 10)
                            ? getCabinClassString(parseInt(cabinClass, 10))
                            : cabinClass}
                    </p>
                </div>
                <div className="grid grid-cols-4 mb-1">
                    <Label>
                        <Trans id="Baggage" />:
                    </Label>
                    <p className="col-span-2">
                        {baggageAllowed
                            ? <Trans id="Luggage included" />
                            : <Trans id="Luggage excluded" />}
                    </p>
                </div>
                {operatingFlightNumber && (
                    <div className="grid grid-cols-4 mb-1">
                        <Label>
                            {equipment?.toLowerCase() === 'bus'
                                ? <Trans id="Bus" />
                                : <Trans id="Flight" />}:
                        </Label>
                        <p className="col-span-2">
                            {operatingFlightNumber}
                        </p>
                    </div>
                )}
                <div className="grid grid-cols-4 mb-1">
                    <Label>
                        <Trans id="Airline" />:
                    </Label>
                    <p className="col-span-2">
                        {localizedAirline.name}
                    </p>
                </div>
                {localizedOperatingAirline.name && localizedOperatingAirline.name !== localizedAirline.name ? (
                    <div className="grid grid-cols-4 mb-1">
                        <Label>
                            <Trans id="Operated by" />:
                        </Label>
                        <p className="col-span-2">
                            {localizedOperatingAirline.name}
                        </p>
                    </div>
                ) : null}
            </div>
            <div className="col-span-full h-px bg-gray-100 -mx-2" />
            {updatedUi ? (
                <SegmentDetailBannerRow
                    type={INBOUND}
                    equipment={equipment}
                    dateTime={arrivalTime}
                    city={destinationCity}
                />
            ) : (
                <SegmentDetailBannerRowDeprecated
                    type={INBOUND}
                    dateTime={arrivalTime}
                    city={destinationCity}
                />
            )}
        </div>
    )
}

export default SegmentDetailsMobile
