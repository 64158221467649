import {
    hasRelevantPriceRule,
    flightHasRelevantFareRule,
    flightHasRelevantAirlineRule,
    hasRelevantBookingDateAndTimeRule,
    flightHasRelevantDepartureOrReturnRule,
} from './rules'
import { ruleStatusesToResult } from './helpers'

export const isAlertForFlight = (rules, flight, airportCodes) => {
    const {
        fareRules,
        airlineRules,
        locationRules,
        locationDateRules,
    } = rules
    const ruleStatuses = [
        flightHasRelevantFareRule(flight, fareRules),
        flightHasRelevantAirlineRule(flight, airlineRules),
        flightHasRelevantDepartureOrReturnRule(locationRules, locationDateRules, flight, airportCodes),
    ]
    return ruleStatusesToResult(ruleStatuses)
}

export const isAlertForGeneralBookingDetails = (rules, price, departureDate) => {
    const {
        priceRules,
        weekDaysApplied,
        bookingDateTimeRules,
        daysBeforeDepartureRules,
    } = rules
    const ruleStatuses = [
        hasRelevantPriceRule(priceRules, price),
        hasRelevantBookingDateAndTimeRule(bookingDateTimeRules, daysBeforeDepartureRules, weekDaysApplied, departureDate),
    ]
    return ruleStatusesToResult(ruleStatuses)
}
