import { useEffect, useState } from 'react'

const useScrollPosition = () => {
    const [scrollPosition, setScrollPosition] = useState(0)
    const detectScrollPosition = () => {
        setScrollPosition(window.scrollY)
    }
    useEffect(() => {
        window.addEventListener('scroll', detectScrollPosition)
        return () => {
            window.removeEventListener('scroll', detectScrollPosition)
        }
    }, [])

    return scrollPosition
}

export default useScrollPosition
