import React from 'react'
import clsx from 'clsx'
import { variant as createVariant } from '../../util/index'

export const ORIENTATION_VERTICAL = 'vertical'
export const ORIENTATION_HORIZONTAL = 'horizontal'

const TextValue = ({
    label,
    children,
    className,
    orientation = ORIENTATION_VERTICAL,
}) => (
    <div
        className={clsx(
            'flex',
            createVariant(orientation, {
                [ORIENTATION_VERTICAL]: 'flex-col',
                [ORIENTATION_HORIZONTAL]: 'flex-row',
            }),
            className,
        )}
    >
        {label && (
            <div className="font-bold text-sm">
                {label}
            </div>
        )}
        <div
            className={clsx(
                'text-sm text-gray-800',
                createVariant(orientation, {
                    [ORIENTATION_VERTICAL]: 'mt-1',
                    [ORIENTATION_HORIZONTAL]: 'ml-2',
                }),
            )}

        >
            {children}
        </div>
    </div>
)

export default TextValue
