import clsx from 'clsx'
import React from 'react'
import Icon from './Icon'

const CarouselCount = ({
    total,
    current,
    className,
    onNextSlide,
    hasNextSlide,
    onPreviousSlide,
    hasPreviousSlide,
}) => (
    <div className="flex items-center">
        <button
            type="button"
            onClick={(e) => {
                e.stopPropagation()
                if (hasPreviousSlide) {
                    onPreviousSlide()
                }
            }}
            className={clsx(
                'transition-colors',
                hasPreviousSlide ? 'text-blue-bright' : 'text-gray-200 cursor-default',
            )}
        >
            <Icon name="chevron-left" />
        </button>
        <div className={clsx('w-8 text-center', className)}>
            {current}/{total}
        </div>
        <button
            type="button"
            onClick={(e) => {
                e.stopPropagation()
                if (hasNextSlide) {
                    onNextSlide()
                }
            }}
            className={clsx(
                'transition-colors',
                hasNextSlide ? 'text-blue-bright' : 'text-gray-200 cursor-default',
            )}
        >
            <Icon name="chevron-right" />
        </button>
    </div>
)

export default CarouselCount
