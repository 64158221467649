import { useCallback } from 'react'
import useAppState from './useAppState'

const useLocalizedCity = () => {
    const { destinations, airportCodes } = useAppState()

    const getLocalizedCity = useCallback((iataCode) => {
        const airport = airportCodes[iataCode] || null
        if (airport === null) {
            return {
                code: iataCode,
            }
        }
        const city = destinations.find(({ name }) => name === airport.name)
        return {
            code: iataCode,
            name: airport.name,
            cityCode: city?.value,
            countryCode: city?.countryCode,
            nameExtension: airport.nameExtension || null,
            isTrainStation: airport.isTrainStation || false,
        }
    }, [airportCodes])

    return getLocalizedCity
}

export default useLocalizedCity
