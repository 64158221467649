import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import debounce from 'lodash.debounce'
import useScrollStatus, { DOWN, UP } from '../hooks/useScrollStatus'
import useScrollPosition from '../hooks/useScrollPosition'

const StickyHeader = ({
    children,
}) => {
    const headerRef = useRef({})
    const [height, setHeight] = useState(0)
    const {
        direction,
        lastPosition,
    } = useScrollStatus()
    const scrollPosition = useScrollPosition()

    const calculateHeight = () => {
        if (headerRef.current) {
            const { scrollHeight } = headerRef.current
            setHeight(scrollHeight)
        }
    }
    const handleResize = debounce(() => {
        calculateHeight()
    }, 200)
    useEffect(() => {
        if (!headerRef.current) {
            return null
        }
        calculateHeight()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [headerRef.current])

    const startedScrolling = height !== 0
        && (direction === UP || (direction === DOWN && scrollPosition > height + 100))
    let top = startedScrolling ? lastPosition : 0
    if (startedScrolling && direction === UP) {
        top = lastPosition - (height + 15)
    }
    const scrolledPastTop = direction === DOWN
        ? top >= scrollPosition + height
        : top >= scrollPosition
    const isFixed = startedScrolling && scrolledPastTop
    return (
        <>
            <div className="h-16 lg:h-28" />
            <div
                ref={headerRef}
                className={clsx(
                    'w-full top-0 left-0 z-header h-16 lg:h-28',
                    isFixed ? 'fixed' : 'absolute',
                )}
                style={{ top: isFixed ? 0 : Math.max(0, top) }}
            >
                {children}
            </div>
        </>
    )
}

export default StickyHeader
