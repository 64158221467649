/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import clsx from 'clsx'
import { render, MARK_LINK } from 'storyblok-rich-text-react-renderer'
import NextLink from 'next/link'
import * as constants from '@connections/constants'
import useFormatFullSlug from '../hooks/useFormatFullSlug'
import { variant } from '../../util/index'

const {
    BACKGROUND_BLUE: BLUE,
    BACKGROUND_BLACK: BLACK,
} = constants

const RichTextLink = ({
    href,
    target,
    linktype,
    children,
}) => {
    const formatFullSlug = useFormatFullSlug()
    if (linktype === 'email') {
        return <a href={`mailto:${href}`}>{children}</a>
    }
    if (href.match(/^(https?:)?\/\//) || target === '_blank') {
        return <a href={href} target={target}>{children}</a>
    }
    return (
        <NextLink legacyBehavior href={formatFullSlug(href)}>
            <a>{children}</a>
        </NextLink>
    )
}

export const resolvers = {
    markResolvers: {
        [MARK_LINK]: (children, props) => (
            <RichTextLink {...props}>
                {children}
            </RichTextLink>
        )
    }
}
const RichTextField = ({
    content,
    className,
    background,
    removeLastMargin,
}) => (
    <div
        className={clsx([
            'prose max-w-none',
            variant(background, {
                [BLUE]: 'text-white',
                [BLACK]: 'text-white',
            }),
            removeLastMargin && 'last:prose-p:mb-0',
            className,
        ])}
    >
        {render(content, {
            ...resolvers
        })}
    </div>
)

export default RichTextField
