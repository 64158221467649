import { useExperiment } from '@statsig/react-bindings'

export default function useFlightSearchExperiment() {
    const experiment = useExperiment(process.env.NEXT_PUBLIC_FLIGHT_EXPERIMENT)
    const isDevelop = process.env.NEXT_PUBLIC_APP_ENV === 'development'

    return {
        showGrid: isDevelop || experiment ? experiment.get('showGrid', false) : false,
        updatedUi: isDevelop || experiment ? experiment.get('updatedUi', true) : true,
        stopsInQsm: isDevelop || experiment ? experiment.get('luggageInQsm', true) : true,
        luggageInQsm: isDevelop || experiment ? experiment.get('luggageInQsm', true) : true,
        inlineLoading: isDevelop || experiment ? experiment.get('inlineLoading', true) : true,
        sortingButtons: isDevelop || experiment ? experiment.get('sortingButtons', true) : true,
        version: isDevelop ? process.env.NEXT_PUBLIC_FLIGHT_EXPERIMENT : experiment?.__evaluation?.group_name || 'Control',
    }
}
