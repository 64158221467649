import React from 'react'
import clsx from 'clsx'

export function convertCategoryToNumber(category) {
    return typeof category === 'number'
        ? category
        : category.split('*').length - 1
}

const Star = (props) => (
    <svg {...props} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.682 5.67408C15.5808 5.38097 15.3165 5.175 15.0075 5.14857L10.5445 4.49756L8.54445 0.44246C8.4134 0.171867 8.13919 0 7.83853 0C7.53788 0 7.26367 0.171867 7.13262 0.44246L5.13252 4.48971L0.669566 5.14857C0.373413 5.19066 0.126986 5.39754 0.0342412 5.68193C-0.0505593 5.95952 0.0248731 6.26125 0.230329 6.46628L3.4697 9.60368L2.68535 14.0588C2.62817 14.359 2.75062 14.6652 2.99909 14.8431C3.24101 15.0161 3.55992 15.0374 3.82266 14.8981L7.83853 12.8038L11.8387 14.9059C11.9488 14.968 12.0731 15.0004 12.1995 15C12.3657 15.0006 12.5277 14.9484 12.6623 14.851C12.9108 14.673 13.0332 14.3669 12.976 14.0666L12.1917 9.61152L15.4311 6.47412C15.6622 6.27829 15.7599 5.96685 15.682 5.67408ZM10.8582 8.81143C10.6714 8.99208 10.5862 9.2535 10.6307 9.5095L11.1954 12.7959L8.24627 11.2272C8.01583 11.1045 7.73943 11.1045 7.50898 11.2272L4.55982 12.7959L5.12456 9.5095C5.16904 9.2535 5.08386 8.99208 4.89709 8.81143L2.54404 6.45838L5.84616 5.97992C6.10417 5.94403 6.32753 5.78239 6.44227 5.54853L7.83841 2.56015L9.31299 5.55637C9.42773 5.79024 9.65109 5.95188 9.9091 5.98777L13.2112 6.46622L10.8582 8.81143Z" fill="#FEC82B" />
        <path d="M3.71987 9.26632V13.9724L7.83771 12.2076L11.9556 13.9724L11.3673 9.26632L14.8969 6.325L10.1908 5.14848L7.83771 1.6189L5.48466 5.14848L1.95508 6.325L3.71987 9.26632Z" fill="#FEC82B" />
    </svg>
)

const HalfStar = () => (
    <svg width="16" height="15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#a)">
            <path d="M7.839 0c-.301 0-.575.172-.706.442l-2 4.048-4.463.659a.784.784 0 0 0-.636.533.784.784 0 0 0 .196.784l3.24 3.138-.785 4.455a.784.784 0 0 0 1.138.84l4.016-2.095V0Z" fill="#FEC82B" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h16v15H0z" />
            </clipPath>
        </defs>
    </svg>
)

const Stars = ({
    total = 0,
    className = '',
}) => (
    <div
        className={clsx(
            'grid grid-flow-col gap-x-2 grid-cols-5',
            className,
        )}
    >
        {Array.from(Array(Math.floor(total))).map((_, i) => <Star key={i} />)}
        {total % 1 !== 0 && (
            <HalfStar />
        )}
    </div>
)

export default Stars
