import React from 'react'
import useFormatDate from '../hooks/useFormatDate'
import Icon from './Icon'

export const INBOUND = 'INBOUND'
export const OUTBOUND = 'OUTBOUND'

const getIconName = (type, city, equipment = '') => {
    if (equipment?.toLowerCase() === 'bus') {
        return 'bus'
    }
    if (city.isTrainStation) {
        return 'train'
    }
    switch (type) {
        case INBOUND:
            return 'plane-arrival'
        case OUTBOUND:
        default:
            return 'plane-departure'
    }
}

export default function SegmentDetailBannerRow({
    city,
    type,
    dateTime,
    equipment,
}) {
    const format = useFormatDate()

    const iconName = getIconName(type, city, equipment)
    const { name, cityCode, nameExtension } = city

    return (
        <>
            <div className="col-span-2 lg:col-span-1 text-sm text-gray-600">
                {format(new Date(dateTime), 'HH:mm')}
            </div>
            <div className="col-span-5 lg:col-span-8 flex items-center gap-2">
                <Icon
                    color="blue"
                    size="smaller"
                    name={iconName}
                    className="flex-shrink-0"
                />
                <div className="flex flex-wrap gap-x-1 text-sm">
                    {name}
                    {nameExtension} [{cityCode}]
                </div>
            </div>
            <p className="col-span-3 text-gray-600 text-right text-sm">
                {format(new Date(dateTime), 'dd.MM.yyyy')}
            </p>
        </>
    )
}
