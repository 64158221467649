import { useEffect } from 'react'

const useResize = (callBack) => {
    useEffect(() => {
        window.addEventListener('resize', callBack)
        callBack()
        return () => window.removeEventListener('resize', callBack)
    }, [])
}

export default useResize
