import React, { forwardRef } from 'react'
import clsx from 'clsx'

const Slide = forwardRef(({
    children,
    isSwiping,
    className = '',
}, ref) => (
    <div
        ref={ref}
        className={clsx(
            'w-full flex-grow-0 flex-shrink-0 whitespace-normal',
            isSwiping && 'pointer-events-none',
            className,
        )}
    >
        {children}
    </div>
))

export default Slide
