import React from 'react'
import clsx from 'clsx'
import { variant as createVariant } from '../../util/index'
import RichTextField from '../content/RichTextField'
import Icon from './Icon'

export const VARIANT_FILLED = 'filled'
export const VARIANT_OUTLINE = 'outline'

export const COLOR_VARIANT_INFO = 'info'
export const COLOR_VARIANT_WARN = 'warn'
export const COLOR_VARIANT_ERROR = 'error'
export const COLOR_VARIANT_SUCCESS = 'success'

const COLORS = {
    [COLOR_VARIANT_ERROR]: 'red-500',
    [COLOR_VARIANT_WARN]: 'orange-500',
    [COLOR_VARIANT_INFO]: 'blue-bright',
    [COLOR_VARIANT_SUCCESS]: 'green-500',
}

const CheckoutInfoCard = ({
    title,
    richText,
    children,
    className,
    iconName = 'info-circle',
    variant = VARIANT_FILLED,
    colorVariant = COLOR_VARIANT_INFO,
}) => {
    const color = COLORS[colorVariant]
    return (
        <div
            className={clsx(
                `flex items-start rounded px-6 py-3 flex-row justify-start bg-${color}`,
                createVariant(variant, {
                    [VARIANT_FILLED]: 'text-white',
                    [VARIANT_OUTLINE]: `border border-${color} bg-opacity-5 text-gray-900`,
                }),
                className,
            )}
        >
            {iconName ? (
                <Icon
                    size="small"
                    name={iconName}
                    className="mr-4 shrink-0"
                    color={variant === VARIANT_FILLED ? 'white' : 'gray-dark'}
                />
            ) : null}
            <div
                className={clsx(
                    'text-left',
                    title && '-mt-0.5'
                )}
            >
                {title ? (
                    <p className="font-medium mb-2">
                        {title}
                    </p>
                ) : null}
                <div className="font-medium text-sm">
                    {children || (
                        <RichTextField
                            content={richText}
                            className={clsx(
                                'text-sm last:prose-p:mb-0',
                                variant === VARIANT_FILLED ? 'text-white' : 'text-gray-900'
                            )}
                        />
                    )}
                </div>
            </div>
        </div>
    )
}

export default CheckoutInfoCard
