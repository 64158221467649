import { useState } from 'react'
import {
    getScreenSize,
    SCREEN_SIZE_SM,
    SCREEN_SIZE_MD,
    SCREEN_SIZE_LG,
    SCREEN_SIZE_XL,
    SCREEN_SIZE_XXL,
} from '../../util/styles'
import useResize from './useResize'

const useScreenSize = () => {
    const [screenSize, setScreenSize] = useState(null)
    useResize(() => setScreenSize(getScreenSize()))
    return {
        screenSize,
        isSm: screenSize === SCREEN_SIZE_SM,
        isMd: screenSize === SCREEN_SIZE_MD,
        isLg: screenSize === SCREEN_SIZE_LG,
        isXl: screenSize === SCREEN_SIZE_XL,
        is2xl: screenSize === SCREEN_SIZE_XXL,
    }
}

export default useScreenSize
