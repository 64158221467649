import { addDays, subDays, format } from 'date-fns'
import { convertCategoryToNumber } from '../../components/utils/Stars'
import * as constants from '@connections/constants'
import { whereType } from '@connections/utils'

const {
    MAX_BOOKING_CHILD_AGE,
    BOOKING_TYPE_TOUR: TOUR,
    INSURANCE_CANCEL: CANCEL,
    BOOKING_TYPE_BREAK: BREAK,
    BOOKING_ITEM_TYPE_TOUR: TOUR_ITEM,
    BOOKING_ITEM_TYPE_HOTEL: HOTEL_ITEM,
    BOOKING_ITEM_TYPE_FLIGHT: FLIGHT_ITEM,
    INSURANCE_TRAVEL_CANCEL: TRAVEL_CANCEL,
    BOOKING_ITEM_TYPE_ACTIVITY: ACTIVITY_ITEM,
    BOOKING_TYPE_TEMPLATE: TEMPLATE,
    BOOKING_ITEM_TYPE_GIFTCARD: GIFTCARD_ITEM,
    BOOKING_ITEM_TYPE_TRANSPORT: TRANSPORT_ITEM,
    BOOKING_ITEM_TYPE_EMISSION_COSTS: EMISSION_COST_ITEM,
} = constants

export const getImageFromTbImages = (picture, images) => {
    let image = picture
    if (!image && images && images.length > 0) {
        [image] = images
    }
    return image ? image.replace('-tmb', '') : null
}

export const getMatchingInsurance = (insurances, selectedService) => {
    if (!selectedService) {
        return null
    }
    const { description } = selectedService
    const selectedType = selectedService.type || description
    if (description.includes('Flight Guarantee')) {
        return insurances.find(whereType('FLIGHT_GUARANTEE')) || null
    }
    if (description.includes('Travel Assistance')) {
        return insurances.find(whereType('TRAVEL')) || null
    }
    if (selectedType.includes('Cancellation Insurance')) {
        return insurances.find(whereType(CANCEL)) || null
    }
    if (selectedType.includes('All In')) {
        return insurances.find(whereType(TRAVEL_CANCEL)) || null
    }
    return null
}

export const getHotelPropsFromHotelDbItem = (hotelItem, travelers) => {
    const {
        data: {
            hotel: {
                name,
                picture,
                address,
                category,
                priceLevels,
                priceLevelName,
            },
            hotelDetails,
        },
        productId,
    } = hotelItem
    const priceLevel = priceLevels.find((level) => level.id === productId)
    const {
        adults,
        endDate,
        children,
        roomName,
        startDate,
        boardBaseDisplayName,
    } = priceLevel
    const hasAddress = address?.city && address?.street
    const amountOfStars = convertCategoryToNumber(category)
    const image = getImageFromTbImages(picture, hotelDetails?.images)
    const numberOfRooms = travelers.reduce((prev, { roomIndex }) => {
        if (roomIndex > prev) {
            return roomIndex
        }
        return prev
    }, 0) + 1
    return {
        name,
        image,
        adults,
        address,
        endDate,
        children,
        startDate,
        hasAddress,
        amountOfStars,
        numberOfRooms,
        boardBaseDisplayName,
        roomName: roomName || priceLevelName,
    }
}

export const getTourPropsFromTourDbItem = (tourItem, travelers) => {
    const {
        data: {
            tour: {
                name,
                images,
                picture,
                endDate,
                startDate,
            },
        },
    } = tourItem
    const image = getImageFromTbImages(picture, images)
    const adults = travelers.filter(whereType('ADULT'))
    const children = travelers.filter(whereType('CHILD'))
    const numberOfRooms = travelers.reduce((prev, { roomIndex }) => {
        if (roomIndex > prev) {
            return roomIndex
        }
        return prev
    }, 0) + 1
    return {
        name,
        image,
        adults,
        endDate,
        children,
        startDate,
        numberOfRooms,
    }
}

export const getAvailablePriceLevelsFromActivityPriceLevels = (priceLevels, departure, landing) => priceLevels.reduce((acc, priceLevel) => {
    const { dateList } = priceLevel
    const availableDates = dateList.filter(({ availableDate }, index) => {
        const availableDateWithoutTime = format(new Date(availableDate), 'yyyy-MM-dd')
        const existingDateIndex = dateList.findIndex((item) => item.availableDate === availableDate)
        return (
            existingDateIndex === index
            && availableDateWithoutTime !== landing
            && availableDateWithoutTime !== departure
        )
    })
    if (availableDates.length === 0) {
        return acc
    }
    return [
        ...acc,
        {
            ...priceLevel,
            dateList: availableDates,
        },
    ]
}, [])

export const getMaxChildAgeFromBooking = (booking) => {
    const defaultMaxChildAge = MAX_BOOKING_CHILD_AGE
    switch (booking?.type) {
        case BREAK:
        case TEMPLATE: {
            const cmsChildAge = booking.data?.cmsBreak?.maxChildAge
                || booking.data?.cmsTour?.maxChildAge
            if (cmsChildAge) {
                return parseInt(cmsChildAge, 10)
            }
            return defaultMaxChildAge
        }
        case TOUR: {
            const tourChildAge = booking.data?.cmsTour?.maxChildAge
            if (tourChildAge) {
                return parseInt(tourChildAge, 10)
            }
            return defaultMaxChildAge
        }
        default:
            return defaultMaxChildAge
    }
}

export const getAirRouteTypeFromTourLeg = (departureDate, endDate, startDate) => {
    const startDateEndMs = addDays(new Date(startDate), 2).getTime()
    const departureDateMs = new Date(departureDate).getTime()
    if (departureDateMs <= startDateEndMs) {
        return 'to'
    }
    const endDateStartMs = subDays(new Date(endDate), 2).getTime()
    if (endDateStartMs <= departureDateMs) {
        return 'from'
    }
    return 'flight'
}

export const getSortedBookingItemsFromBooking = ({ items, travelers }) => {
    const tourItems = items.filter(whereType(TOUR_ITEM))
    const flightItem = items.find(whereType(FLIGHT_ITEM))
    const hotelItems = items.filter(whereType(HOTEL_ITEM))
    const activityItems = items.filter(whereType(ACTIVITY_ITEM))
    const giftcardItems = items.filter(whereType(GIFTCARD_ITEM))
    const transportItems = items.filter(whereType(TRANSPORT_ITEM))
    const emissionCostItem = items.find(whereType(EMISSION_COST_ITEM))
    hotelItems.sort((a, b) => {
        const { startDate: startA } = getHotelPropsFromHotelDbItem(a, travelers)
        const { startDate: startB } = getHotelPropsFromHotelDbItem(b, travelers)
        return new Date(startA) - new Date(startB)
    })
    activityItems.sort((a, b) => {
        const { date: startA } = a.data
        const { date: startB } = b.data
        return new Date(startA) - new Date(startB)
    })
    transportItems.sort((a, b) => {
        const { startDate: startA } = a.data.transport.routingList[0]
        const { startDate: startB } = b.data.transport.routingList[0]
        return new Date(startA) - new Date(startB)
    })
    return {
        tourItems,
        flightItem,
        hotelItems,
        activityItems,
        giftcardItems,
        transportItems,
        emissionCostItem,
    }
}

export const getAbsoluteEndDateFromFares = (fares) => {
    const lastFlightAirRoutes = fares?.[fares?.length - 1]?.airRoutes
    const lastFlightSegments = lastFlightAirRoutes?.[lastFlightAirRoutes?.length - 1].segments
    return lastFlightSegments?.[lastFlightSegments?.length - 1]?.arrivalTime
}
