import React from 'react'
import useFormatDate from '../hooks/useFormatDate'
import Icon from './Icon'

export const INBOUND = 'INBOUND'
export const OUTBOUND = 'OUTBOUND'

const getIconName = (type, city) => {
    if (city.isTrainStation) {
        return 'train'
    }
    switch (type) {
        case INBOUND:
            return 'plane-arrival'
        case OUTBOUND:
        default:
            return 'plane-departure'
    }
}

const SegmentDetailBannerRowDeprecated = ({
    city,
    type,
    dateTime,
}) => {
    const format = useFormatDate()
    const { name, nameExtension } = city
    const iconName = getIconName(type, city)
    return (
        <>
            <div className="col-span-2 lg:col-span-1 flex font-bold">
                {format(new Date(dateTime), 'HH:mm')}
            </div>
            <div className="col-span-5 lg:col-span-8 flex gap-2">
                <Icon
                    color="blue"
                    size="smaller"
                    name={iconName}
                    className="flex-shrink-0"
                />
                <div className="flex flex-wrap gap-x-1">
                    <span>{name}</span>
                    {nameExtension && (
                        <span>({nameExtension})</span>
                    )}
                </div>
            </div>
            <p className="col-span-3 text-gray-400 text-right">
                {format(new Date(dateTime), 'dd.MM.yyyy')}
            </p>
        </>
    )
}

export default SegmentDetailBannerRowDeprecated
