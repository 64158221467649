import React from 'react'
import clsx from 'clsx'
import { motion } from 'framer-motion'

const SliderContainer = ({
    position,
    children,
    className = '',
    isDisabled,
    swipingProps,
    containerRef,
}) => (
    <motion.div
        ref={containerRef}
        className={clsx(
            'whitespace-nowrap flex flex-nowrap touch-pan-y',
            !isDisabled && 'cursor-move select-none',
            className,
        )}
        transition={{ duration: 0.4, ease: 'easeOut' }}
        animate={{ x: position !== null && `${position}%` }}
        {...swipingProps}
    >
        {children}
    </motion.div>
)

export default SliderContainer
