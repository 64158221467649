import React from 'react'

export default function Page({
    children,
    ...props
}) {
    return (
        // Preview container do not remove
        <div {...props}>
            {children}
        </div>
    )
}
