import queryString from 'query-string'
import * as constants from '@connections/constants'
import { getFullLocale } from '@connections/utils'
import routes from '../../next.config.routes'

const {
    LANGUAGE_EN: EN,
    LANGUAGE_NL_BE: NL_BE,
    LANGUAGE_FR_BE: FR_BE,
    DEFAULT_LANGUAGE_WEB: DEFAULT_LANGUAGE,
} = constants

export const getLinkLocale = (fullLocale, path) => {
    if (path === '/' && fullLocale === DEFAULT_LANGUAGE) {
        return ''
    }
    return fullLocale
}

export const getLocaleRoute = ({
    locale,
    pathname,
    query = {},
    params,
}) => {
    const parsedLocale = locale === '' ? DEFAULT_LANGUAGE : locale
    if (!getLinkLocale(parsedLocale, pathname)) {
        return '/'
    }
    let href = `/${parsedLocale}${pathname}`
    if (routes[pathname]) {
        href = `/${parsedLocale}${routes[pathname][parsedLocale]}`
    }
    Object.entries(query).forEach(([key, value]) => {
        href = href.replace(`[${key}]`, value)
    })
    if (typeof params === 'undefined') {
        return href
    }
    const parsedQueryString = queryString.stringify(params)
    return `${href}?${parsedQueryString}`
}

export const FLIGHT_SEARCH_PATH_NL_BE = '/vliegtickets/zoeken'
export const FLIGHT_SEARCH_PATH_EN = '/flights/search'
export const FLIGHT_SEARCH_PATH_FR_BE = '/vols/chercher'

export const FLIGHT_SEARCH_PATHS = {
    [NL_BE]: FLIGHT_SEARCH_PATH_NL_BE,
    [EN]: FLIGHT_SEARCH_PATH_EN,
    [FR_BE]: FLIGHT_SEARCH_PATH_FR_BE,
}

export function getSlugsFromPageStory(pageStory) {
    const translatedSlugs = pageStory
        ? pageStory.translatedSlugs
        : [{
            lang: 'en',
            path: 'home',
        }, {
            lang: 'nl',
            path: 'home',
        }, {
            lang: 'fr',
            path: 'home',
        }]
    return translatedSlugs.map(({ lang, path }) => ({
        simpleLocale: lang,
        fullLocale: getFullLocale(lang),
        path: path === 'home' ? '/' : `/${path}`
    }))
}
