import { formatFullSlug } from '../../util/index'
import usePathnameLocale from './usePathnameLocale'

const useFormatFullSlug = () => {
    const locale = usePathnameLocale()
    return (fullSLug, options = {}) => (
        formatFullSlug(fullSLug, { locale, ...options })
    )
}

export default useFormatFullSlug
