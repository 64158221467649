import React, { useState } from 'react'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'

const Collapsable = ({
    icon,
    title,
    isOpen,
    onExpand,
    children,
    className,
    disabled = false,
    testId = 'CollapsableHeader',
}) => {
    const [isCollapsing, setIsCollapsing] = useState(false)
    const handleCollapseStart = () => setIsCollapsing(true)
    const handleCollapseComplete = () => setIsCollapsing(false)
    return (
        <>
            <button
                tabIndex={0}
                type="button"
                onClick={onExpand}
                disabled={disabled}
                onKeyDown={onExpand}
                className={clsx(
                    'w-full flex flex-row items-center justify-between',
                    className
                )}
                data-testid={testId}
            >
                {title}
                <motion.div
                    animate={isOpen ? 'open' : 'closed'}
                    variants={{
                        open: { rotate: 180 },
                        closed: { rotate: 0 },
                    }}
                >
                    {icon}
                </motion.div>
            </button>
            <AnimatePresence initial={false}>
                {isOpen && (
                    <motion.div
                        key="content"
                        animate="open"
                        exit="collapsed"
                        initial="collapsed"
                        className={clsx(
                            isCollapsing && 'overflow-hidden'
                        )}
                        transition={{ duration: 0.3 }}
                        variants={{
                            open: { opacity: 1, height: 'auto' },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        onAnimationStart={handleCollapseStart}
                        onAnimationComplete={handleCollapseComplete}
                        data-testid="CollapsableContent"
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    )
}
export default Collapsable
