/* eslint-disable quote-props */
module.exports = {
    '/intense-ervaringen/zoeken': {
        'nl-be': '/intense-ervaringen/zoeken',
        'en': '/experiences/search',
        'fr-be': '/experiences/chercher',
    },
    '/travel-designers/zoeken': {
        'nl-be': '/travel-designers/zoeken',
        'en': '/travel-designers/search',
        'fr-be': '/travel-designers/chercher',
    },
    '/reizen/zoeken': {
        'nl-be': '/reizen/zoeken',
        'en': '/travel/search',
        'fr-be': '/voyages/chercher',
    },
    '/vliegtickets/zoeken': {
        'nl-be': '/vliegtickets/zoeken',
        'en': '/flights/search',
        'fr-be': '/vols/chercher',
    },
    '/reiswinkels': {
        'nl-be': '/reiswinkels',
        'en': '/travel-shops',
        'fr-be': '/boutique-de-voyage',
    },
    '/inspiratie': {
        'nl-be': '/inspiratie',
        'en': '/inspiratie',
        'fr-be': '/inspiratie',
    },
    '/jobs': {
        'nl-be': '/jobs',
        'en': '/jobs',
        'fr-be': '/jobs',
    },
    '/hotels': {
        'nl-be': '/hotels',
        'en': '/hotels',
        'fr-be': '/hotels',
    },
}
