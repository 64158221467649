import { useEffect, useRef, useState } from 'react'

export const DOWN = 'down'
export const UP = 'up'

const useScrollStatus = () => {
    const count = useRef(0)
    const [scrollStatus, setScrollStatus] = useState({
        direction: null,
        position: null,
        lastPosition: null,
    })
    const detectScrollPosition = () => {
        const position = window.scrollY
        setScrollStatus((prev) => {
            if (prev.position === null) {
                return {
                    direction: null,
                    position,
                    lastPosition: position,
                }
            }
            const direction = position > prev.position ? DOWN : UP
            const directionChanged = direction !== prev.direction
            const lastPosition = directionChanged ? position : prev.lastPosition
            // Sometimes when scrolling the direction changes is triggered for no reaseon
            // By adding a count we only change the direction if directionChange is triggired multiple times
            if (directionChanged && count.current <= 6) {
                count.current += 1
                return prev
            }
            count.current = 0
            return {
                direction,
                position,
                lastPosition,
            }
        })
    }
    useEffect(() => {
        window.addEventListener('scroll', detectScrollPosition)
        return () => {
            window.removeEventListener('scroll', detectScrollPosition)
        }
    }, [])

    return scrollStatus
}

export default useScrollStatus
