import { parseISO, addDays } from 'date-fns'
import { whereCode } from '@connections/utils'
import { formatInTimeZone } from 'date-fns-tz'
import { captureException } from '@sentry/nextjs'
import {
    applyRule,
    valueMatchesList,
    getMsFromTimeString,
    ruleStatusesToResult,
    booleanMatchesYesNoList,
    amountOfWorkDaysBeforeToCalendarDaysBefore,
} from './helpers'

export const flightHasRelevantAirlineRule = (flight, rules) => {
    if (rules.length === 0) {
        return null
    }
    const flightAirlines = flight.airRoutes.reduce((acc, { segments }) => {
        const airRouteAirlines = segments.map(({ carrier }) => carrier)
        return [...acc, ...airRouteAirlines]
    }, [])
    return rules.some(({ match, exclude, iataCodes }) => {
        switch (match) {
            case 'any-segment': {
                const hasMatchingAirlines = flightAirlines.some((airline) => iataCodes.includes(airline))
                return applyRule(hasMatchingAirlines, exclude)
            }
            case 'all-segments': {
                const hasMatchingAirlines = flightAirlines.every((airline) => iataCodes.includes(airline))
                return applyRule(hasMatchingAirlines, exclude)
            }
            case 'plating-carrier': {
                const hasMatchingPlatingCarrier = iataCodes.includes(flight.platingCarrier)
                return applyRule(hasMatchingPlatingCarrier, exclude)
            }
            default:
                // This should not crash the page but we should be notified
                captureException(new Error(`Unknown match in alert airline rule: ${match}`))
                return false
        }
    })
}

export const hasRelevantDateTimeRule = (rules, referenceDate) => {
    if (rules.length === 0) {
        return null
    }
    return rules.some(({
        from,
        until,
        exclude,
        variant,
    }) => {
        const [fromDateString, fromTimeString] = from.split(' ')
        const [untilDateString, untilTimeString] = until.split(' ')
        switch (variant) {
            case 'date': {
                const fromDate = parseISO(fromDateString)
                const untilDate = parseISO(untilDateString)
                const isBetween = fromDate <= referenceDate && referenceDate <= untilDate
                return applyRule(isBetween, exclude)
            }
            case 'time': {
                const [fromHours, fromMinutes] = fromTimeString.split(':')
                const [untilHours, untilMinutes] = untilTimeString.split(':')
                const [currentHours, currentMinutes] = formatInTimeZone(referenceDate, 'Europe/Brussels', 'HH:mm').split(':')
                const fromMs = getMsFromTimeString(fromHours, fromMinutes)
                const untilMs = getMsFromTimeString(untilHours, untilMinutes)
                const currentMs = getMsFromTimeString(currentHours, currentMinutes)
                const isBetween = fromMs <= currentMs && currentMs <= untilMs
                return applyRule(isBetween, exclude)
            }
            default:
                // This should not crash the page but we should be notified
                captureException(new Error(`Unknown variant in alert dateTime rule: ${variant}`))
                return false
        }
    })
}

export const hasRelevantDaysBeforeDepartureRule = (rules, departureString) => {
    if (rules.length === 0) {
        return true
    }
    const today = new Date()
    const departureDate = parseISO(departureString)
    return rules.some(({
        exclude,
        variant,
        start: startString,
        end: endString = '0',
    }) => {
        const minAmountOfDaysBefore = parseInt(endString, 10)
        const maxAmountOfDaysBefore = parseInt(startString, 10)
        switch (variant) {
            case 'calendar-days': {
                const end = addDays(departureDate, -minAmountOfDaysBefore)
                const start = addDays(departureDate, -maxAmountOfDaysBefore)
                const todayIsBetween = start <= today && today <= end
                return applyRule(todayIsBetween, exclude)
            }
            case 'week-days': {
                const minAmountOfCalendarDaysBefore = amountOfWorkDaysBeforeToCalendarDaysBefore(minAmountOfDaysBefore, departureDate)
                const maxAmountOfCalendarDaysBefore = amountOfWorkDaysBeforeToCalendarDaysBefore(maxAmountOfDaysBefore, departureDate)
                const end = addDays(departureDate, -minAmountOfCalendarDaysBefore)
                const start = addDays(departureDate, -maxAmountOfCalendarDaysBefore)
                const todayIsBetween = start <= today && today <= end
                return applyRule(todayIsBetween, exclude)
            }
            default:
                // This should not crash the page but we should be notified
                captureException(new Error(`Unknown variant in alert days before departure rule: ${variant}`))
                return false
        }
    })
}

export const shouldApplyBookingDateAndTimeRules = (weekdaysApplied) => {
    if (weekdaysApplied.length === 0) {
        return true
    }
    const currentDay = new Date().getDay()
    return weekdaysApplied.includes(currentDay)
}

export const hasRelevantBookingDateAndTimeRule = (dateTimeRules, daysBeforeDepartureRules, weekdaysApplied, departureDate) => {
    if (!shouldApplyBookingDateAndTimeRules(weekdaysApplied)) {
        return true
    }
    const ruleStatuses = [
        hasRelevantDateTimeRule(dateTimeRules, new Date()),
        hasRelevantDaysBeforeDepartureRule(daysBeforeDepartureRules, departureDate),
    ]
    return ruleStatusesToResult(ruleStatuses)
}

export const flightHasRelevantFareRule = (flight, rules) => {
    const { provider, airRoutes, priceBreakdown } = flight
    if (rules.length === 0 || !priceBreakdown) {
        return null
    }
    return rules.some(({
        exclude,
        providers,
        fareBases,
        isPrivate,
        isNegotiated,
        cabinClasses,
        bookingClasses,
        isBaggageAllowed,
    }) => {
        const [{ cabinClass, classOfService, baggageAllowed }] = airRoutes[0].segments
        const [{ fareBasis, privateFare, negotiatedFare }] = priceBreakdown.find(({ ageTypes }) => ageTypes.find(whereCode('ADT'))).fareInformation
        const hasMatchingProvider = valueMatchesList(providers, provider)
        const hasMatchingFareBase = valueMatchesList(fareBases, fareBasis)
        const hasMatchingCabinClass = valueMatchesList(cabinClasses, cabinClass)
        const hasMatchingBookingClass = valueMatchesList(bookingClasses, classOfService)
        const hasMatchingPrivateSetting = booleanMatchesYesNoList(isPrivate, privateFare)
        const hasMatchingNegotiatedSetting = booleanMatchesYesNoList(isNegotiated, negotiatedFare)
        const hasMatchingBaggageAllowedSetting = booleanMatchesYesNoList(isBaggageAllowed, baggageAllowed)
        const isRelevant = hasMatchingProvider
            && hasMatchingFareBase
            && hasMatchingCabinClass
            && hasMatchingBookingClass
            && hasMatchingPrivateSetting
            && hasMatchingNegotiatedSetting
            && hasMatchingBaggageAllowedSetting
        return applyRule(isRelevant, exclude)
    })
}

export const hasRelevantPriceRule = (rules, price) => {
    if (rules.length === 0) {
        return null
    }
    return rules.some(({
        exclude,
        priceLowerOrEqualThan: maxPriceString,
        priceHigherOrEqualThan: minPriceString,
    }) => {
        let isMatch = true
        const maxPrice = parseFloat(maxPriceString)
        const minPrice = parseFloat(minPriceString)
        if (minPrice && maxPrice) {
            isMatch = minPrice <= price && price <= maxPrice
        } else if (minPrice) {
            isMatch = price >= minPrice
        } else if (maxPrice) {
            isMatch = price <= maxPrice
        }
        return applyRule(isMatch, exclude)
    })
}

export const flightHasRelevantLocationRule = (rules, departure, arrival, airportCodes) => {
    if (rules.length === 0) {
        return null
    }
    return rules.some(({
        variant,
        countryCodes,
        excludeAirports,
        excludeCountries,
        airportIataCodes,
    }) => {
        const referenceAirport = variant === 'arrival'
            ? arrival.code
            : departure.code
        const referenceCountry = airportCodes[referenceAirport].countryCode
        const hasMatchingAirport = valueMatchesList(airportIataCodes, referenceAirport)
        const shouldApplyAirportRule = applyRule(hasMatchingAirport, excludeAirports)
        const hasMatchingCountry = valueMatchesList(countryCodes, referenceCountry)
        const shouldApplyCountryRule = applyRule(hasMatchingCountry, excludeCountries)
        return shouldApplyAirportRule && shouldApplyCountryRule
    })
}

export const flightHasRelevantDepartureOrReturnRule = (locationRules, dateRules, flight, airportCodes) => {
    const { segments } = flight.airRoutes[0]
    const [{ origin, departureTime }] = segments
    const { destination, arrivalTime } = segments[segments.length - 1]
    const arrivalDate = parseISO(arrivalTime)
    const departureDate = parseISO(departureTime)
    const ruleStatuses = [
        hasRelevantDateTimeRule(dateRules, arrivalDate),
        hasRelevantDateTimeRule(dateRules, departureDate),
        flightHasRelevantLocationRule(locationRules, origin, destination, airportCodes),
    ]
    return ruleStatusesToResult(ruleStatuses)
}
